
.data-table(:class="dataTableClasses")
  div(:class="{ 'py-3': true, 'px-3': embed }")
    stack(v-if="!disablePagination")
      stack-item
        b-form-select(
          v-model="perPageModel"
          :options="[ 5, 10, 15, 25, 50, 100 ]"
        )
      stack-item
        .text-muted {{ $t('pagination.per_page') }}
      stack-item(fill)
      stack-item
        b-pagination.m-0(
          v-model="pageModel"
          :total-rows="totalItems"
          :per-page="perPage"
          v-if="totalPages > 1"
        )
  table.table
    thead
      tr
        th(
          v-for="header in headers" 
          :key="header.key"
          :class="headerClasses(header)"
          :style="headerStyles(header)"
          @click="onHeaderClick($event, header)"
          v-b-tooltip.hover.top
          :title="header.tooltip"
        )
          .data-table__header-cel
            .data-table__header-title
              slot(
                :name="`header_${header.key}`"
              ) {{ header.label }}
            .data-table__sort
              span.mdi.mdi-menu-up
              span.mdi.mdi-menu-down
      tr.data-table__progress-row(:class="{ active: progress }")
        th(:colspan="headers.length")
          b-progress(:value="100" variant="primary" striped animated)
    tbody
      tr(v-for="(item, rowIndex) in items" :key="rowIndex")
        td(
          v-for="(header, celIndex) in headers" 
          :key="`${rowIndex}_${celIndex}_${header.key}`"
          :class="celClasses(header)"
        )
          slot(
            :name="`cel_${header.key}`"
            :index="rowIndex"
            :item="item"
          ) {{ item[header.key] }}
          
      tr.table-info-row(v-if="progress && !items.length")
        td(:colspan="headers.length")
          .py-5.text-center
            .h4.text-secondary.text-uppercase {{ $t('rest.no_data') }}
            .text-secondary {{ $t('rest.fetching_data') }}

      tr.table-info-row(v-if="!progress && !items.length")
        td(:colspan="headers.length")
          .py-5.text-center
            .h4.text-secondary.text-uppercase {{ $t('rest.no_data') }}
            .text-secondary {{ $t('rest.empty') }}
  div(:class="{ 'py-3': true, 'px-3': embed }")
    stack(v-if="!disablePagination")
      stack-item
        b-form-select(
          v-model="perPageModel"
          :options="[ 5, 10, 15, 25, 50, 100 ]"
        )
      stack-item(fill)
      stack-item
        b-pagination.m-0(
          v-model="pageModel"
          :total-rows="totalItems"
          :per-page="perPage"
          v-if="totalPages > 1"
        )
