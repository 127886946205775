import Vue from 'vue';
import VeeValidate from 'vee-validate';
import store from '@/store';
import { i18n, ValidatorDictionaries } from '@/localization';

Vue.use(VeeValidate, {
  i18nRootKey: 'validations',
  i18n,
  inject: false,
  dictionary: ValidatorDictionaries,
  locale: store.getters.language
});
