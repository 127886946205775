
div
  .p-4
    .container
      stack
        stack-item(fill)
          .h3.mb-0 {{ $t('companies.tabs.features') }}
      data-table(
        :embed="true",
        :progress="inProgress",
        :headers="headers",
        :items="tableItems",
        ref="companyFeaturesTable",
        :disablePagination="true"
      )
        template(slot="cel_is_enabled", slot-scope="{ item }")
          b-form-checkbox(
            v-model="item.is_enabled",
            switch,
            @change="changeEnabled(item)"
          )
        template(slot="cel_settings", slot-scope="{ item }")
          template(v-if="item.value === 'dimensions_dependence'")
            div
              b-form-group(
                :label="$t(`companies.features.dimension_number`, {number: 1})",
                :invalid-feedback="errors.first('dimension_1')"
                :state="errors.has('dimension_1') ? false : null"
                label-cols="4"
              )
                company-dimension-names-select(
                  :companyId="$route.params.id"
                  v-model="item.settings.dimension_1"
                  v-validate="{required: item.is_enabled}"
                  data-vv-name="dimension_1"
                  :data-vv-as="$t(`companies.features.dimension_number`, {number: 1})",
                  :disabled="!item.is_enabled"
                  @input="changeDimension"
                )
              b-form-group(
                :label="$t(`companies.features.dimension_number`, {number: 2})",
                :invalid-feedback="errors.first('dimension_2')"
                :state="errors.has('dimension_2') ? false : null"
                label-cols="4"
              )
                company-dimension-names-select(
                  :companyId="$route.params.id"
                  v-model="item.settings.dimension_2"
                  v-validate="{required: item.is_enabled}"
                  data-vv-name="dimension_2"
                  :disabled="!item.is_enabled"
                  :data-vv-as="$t(`companies.features.dimension_number`, {number: 2})",
                  @input="changeDimension"
                )
              .d-flex.justify-content-end(v-if="dimensionFeatureChanged && item.is_enabled")
                b-btn(
                  variant="primary"
                  squared
                  :disabled="dimensionFeatureInProgress"
                  @click="saveDimensions"
                ) {{ $t('actions.save') }}
          template(v-else-if="Object.entries(item.settings).length !== 0")
            b-form-checkbox(
              v-for="(value, name) in item.settings",
              :key="name",
              v-if="['cost_centers_set', 'prioritize_keywords_methods', 'prioritize_vendors_methods'].includes(name)",
              v-model="item.settings[name]",
              switch,
              @change="changeEnabled(item)"
            ) {{ $t(`companies.features.${name}`) }}
          template(v-else)
            p None
      .p(v-if="automationFeatureChanged")
        div.d-flex.justify-content-center
          div.alert.alert-warning
            .mdi.mdi-alert-box
              | {{ $t(`companies.features.automation_feature_reminder`) }}
        div.d-flex.justify-content-center
          stack
            stack-item(fill)
              b-form-group(
                label-for="bic-input",
                label-cols="0",
                :invalid-feedback="errors.first('comment')"
                :state="errors.has('comment') ? false : null"
              )
                b-input(
                  v-model="invoiceGuesserFeature.comment",
                  v-validate="'required'"
                  data-vv-name="comment"
                  :data-vv-as="$t('companies.features.changes_comment')"
                  placeholder="Changes comment"
                )
            stack-item
              b-btn(variant="primary", squared, @click="changeSettings(null)")
                .mdi.mdi-account-check-outline
                | {{ $t('actions.save') }}

      .form-container.mt-5
        h5 {{ $t('companies.features.settings') }}
        b-form-group(
          :label="$t(`companies.features.automate_accounting`)",
          label-for="automate-accounting-input",
          label-cols="4"
        )
          b-form-checkbox#automate-accounting-input(
            v-model="company.automate_accounting",
            switch
          )
        b-form-group(
          :label="$t(`companies.features.deduct_vat`)",
          label-for="deduct-vat-input",
          label-cols="4"
        )
          b-form-checkbox#deduct-vat-input(
            v-model="company.deduct_vat",
            switch
          )
        b-form-group(
          :label="$t(`companies.features.overwrite_suggestions`)",
          label-for="overwrite-suggestions-input",
          label-cols="4"
        )
          b-form-checkbox#overwrite-suggestions-input(
            v-model="company.overwrite_suggestions",
            switch
          )
        b-form-group(
          :label="$t(`companies.features.voucher_date`)",
          label-for="voucher-date-input",
          label-cols="4"
        )
          b-form-checkbox#voucher-date-input(
            v-model="invoiceGuesserFeature.settings.voucher_date",
            switch
          )
        b-form-group(
          :label="$t('companies.features.comment_user')",
          label-for="comment-user-input",
          label-cols="4"
        )
          b-input#comment-user-input(
            v-model="invoiceGuesserFeature.comment_user",
          )
        b-form-group(
          :label="$t('companies.basic.iban')",
          label-for="iban-input",
          label-cols="4"
        )
          b-input#iban-input(v-model="company.iban")

        b-form-group(
          :label="$t('companies.basic.bic')",
          label-for="bic-input",
          label-cols="4"
        )
          b-input#bic-input(v-model="company.bic")
        stack
          stack-item(fill)
          stack-item
            b-btn(variant="primary", squared, @click="changeSettings(null)")
              .mdi.mdi-account-check-outline
              | {{ $t('actions.save') }}
