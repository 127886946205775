<template lang="pug">
.data-table(:class="dataTableClasses")
  div(:class="{ 'py-3': true, 'px-3': embed }")
    stack(v-if="!disablePagination")
      stack-item
        b-form-select(
          v-model="perPageModel"
          :options="[ 5, 10, 15, 25, 50, 100 ]"
        )
      stack-item
        .text-muted {{ $t('pagination.per_page') }}
      stack-item(fill)
      stack-item
        b-pagination.m-0(
          v-model="pageModel"
          :total-rows="totalItems"
          :per-page="perPage"
          v-if="totalPages > 1"
        )
  table.table
    thead
      tr
        th(
          v-for="header in headers" 
          :key="header.key"
          :class="headerClasses(header)"
          :style="headerStyles(header)"
          @click="onHeaderClick($event, header)"
          v-b-tooltip.hover.top
          :title="header.tooltip"
        )
          .data-table__header-cel
            .data-table__header-title
              slot(
                :name="`header_${header.key}`"
              ) {{ header.label }}
            .data-table__sort
              span.mdi.mdi-menu-up
              span.mdi.mdi-menu-down
      tr.data-table__progress-row(:class="{ active: progress }")
        th(:colspan="headers.length")
          b-progress(:value="100" variant="primary" striped animated)
    tbody
      tr(v-for="(item, rowIndex) in items" :key="rowIndex")
        td(
          v-for="(header, celIndex) in headers" 
          :key="`${rowIndex}_${celIndex}_${header.key}`"
          :class="celClasses(header)"
        )
          slot(
            :name="`cel_${header.key}`"
            :index="rowIndex"
            :item="item"
          ) {{ item[header.key] }}
          
      tr.table-info-row(v-if="progress && !items.length")
        td(:colspan="headers.length")
          .py-5.text-center
            .h4.text-secondary.text-uppercase {{ $t('rest.no_data') }}
            .text-secondary {{ $t('rest.fetching_data') }}

      tr.table-info-row(v-if="!progress && !items.length")
        td(:colspan="headers.length")
          .py-5.text-center
            .h4.text-secondary.text-uppercase {{ $t('rest.no_data') }}
            .text-secondary {{ $t('rest.empty') }}
  div(:class="{ 'py-3': true, 'px-3': embed }")
    stack(v-if="!disablePagination")
      stack-item
        b-form-select(
          v-model="perPageModel"
          :options="[ 5, 10, 15, 25, 50, 100 ]"
        )
      stack-item(fill)
      stack-item
        b-pagination.m-0(
          v-model="pageModel"
          :total-rows="totalItems"
          :per-page="perPage"
          v-if="totalPages > 1"
        )
</template>

<script>
export default {
  props: {
    progress: {
      type: Boolean,
      default: false,
    },
    headers: {
      type: Array,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    embed: {
      type: Boolean,
      default: false,
    },
    sortBy: {
      type: String,
      default: null,
    },
    sortOrder: {
      type: String,
      default: "asc",
    },
    search: {
      type: String,
      default: null,
    },
    page: {
      type: Number,
      default: null,
    },
    perPage: {
      type: Number,
      default: null,
    },
    totalItems: {
      type: Number,
      default: null,
    },
    totalPages: {
      type: Number,
      default: null,
    },
    disablePagination: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    pageModel: {
      get() {
        return this.page ?? 1;
      },
      set(value) {
        this.$emit("paginate", {
          perPage: this.perPageModel,
          page: value,
        });
      },
    },
    perPageModel: {
      get() {
        return this.perPage ?? null;
      },
      set(value) {
        this.$emit("paginate", {
          perPage: value,
          page: this.pageModel,
        });
      },
    },
    dataTableClasses() {
      const list = {};
      list["data-table-embed"] = this.embed;
      return list;
    },
  },

  methods: {
    headerClasses(header) {
      return {
        squeeze: !!header.squeeze,
        sortable: !!header.sortable,
        nowrap: !(header.wrap ?? true),
        "is-sorted": this.sortBy === header.key,
        [`sort-order--${this.sortOrder}`]: this.sortBy === header.key,
      };
    },

    headerStyles(header) {
      return {
        width: header.width,
      };
    },

    celClasses(header) {
      const list = {};

      list["squeeze"] = !!header.squeeze;
      list["is-sorted"] = this.sortBy === header.key;

      return list;
    },

    onHeaderClick(e, header) {
      this.$emit("headerClick", header);

      if (header.sortable) {
        const by = this.sortBy;
        const order = this.sortOrder;
        let sortBy = by;
        let sortOrder = order;
        if (header.key === by) {
          if (order === "desc") {
            sortOrder = "asc";
          } else {
            sortBy = null;
            sortOrder = "desc";
          }
        } else {
          sortBy = header.key;
          sortOrder = "desc";
        }
        this.$emit("sort", { sortBy, sortOrder });
      }
    },
  },
};
</script>

<style lang="scss">
@import "@/assets/stylesheet/_variables.scss";

$embed-padding: 1.5rem;

.data-table {
  font-size: 14px;

  // & .table {
  //   position: relative;
  // }

  // & .table thead {
  //   position: sticky;
  //   top: 70px;
  // }

  & .table {
    margin: 0;
    border-bottom: 1px solid $bb-gray-900;
  }

  & .table th {
    background: $white;
    font-weight: 500;
    transition: all $bb-duration;
    border-top: 1px solid $bb-gray-900;
    border-bottom: 1px solid $bb-gray-800;
    vertical-align: middle;

    &.squeeze {
      width: 0.1%;
    }

    &.sortable {
      cursor: pointer;

      &:hover {
        background: mix($primary, $white, 10%);
        color: $primary;
        border-bottom-color: $primary;
      }
    }

    &.nowrap {
      white-space: nowrap;
    }
  }

  & .table td {
    transition: all $bb-duration;
    border-top: 1px solid $bb-gray-900;
    vertical-align: middle;
  }

  & .table tr:hover td {
    background: mix($white, $bb-gray-900, 50%);
  }

  & .table th.is-sorted,
  & .table td.is-sorted {
    background: mix($white, $bb-gray-900, 50%);
  }

  & .table tr:hover td.is-sorted {
    background: $bb-gray-900;
  }

  & .table #{&}__progress-row {
    & th {
      border-color: transparent;
      padding: 0 !important;
      position: relative;
    }

    & .progress {
      border-radius: 0;
      position: absolute;
      width: 100%;
      left: 0;
      height: 0px;
      transition: all $bb-duration;
    }

    &.active .progress {
      height: 4px;
    }
  }

  & .table-info-row td {
    background: mix($white, $bb-gray-900, 50%) !important;
  }
}

.data-table__header-cel {
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
}

.data-table__header-title {
  flex-grow: 1;
}

.data-table__sort {
  display: none;
  position: relative;
  flex-shrink: 0;
  margin-left: 10px;
  width: 10px;
  height: 20px;

  span {
    pointer-events: none;
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 20px;
    color: $bb-gray-800;
    transform: translate(-50%, -50%);
    transition: all $bb-duration;

    &:first-child {
      margin-top: -5px;
    }

    &:last-child {
      margin-top: 5px;
    }
  }

  .table th:hover & span {
    color: mix($white, $primary, 70%);
  }

  .table th.sort-order--asc & span:first-child,
  .table th.sort-order--desc & span:last-child {
    color: $primary;
  }
}

.table th.sortable .data-table__sort {
  display: block;
}

.data-table-embed {
  & .table td:first-child,
  & .table th:first-child {
    padding-left: $embed-padding;
  }

  & .table td:last-child,
  & .table th:last-child {
    padding-right: $embed-padding;
  }
}
</style>
